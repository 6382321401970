// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-alsterkrone-js": () => import("./../../../src/pages/projects/alsterkrone.js" /* webpackChunkName: "component---src-pages-projects-alsterkrone-js" */),
  "component---src-pages-projects-building-a-knife-js": () => import("./../../../src/pages/projects/building-a-knife.js" /* webpackChunkName: "component---src-pages-projects-building-a-knife-js" */),
  "component---src-pages-projects-megadunes-ecolodges-competition-js": () => import("./../../../src/pages/projects/megadunes-ecolodges-competition.js" /* webpackChunkName: "component---src-pages-projects-megadunes-ecolodges-competition-js" */),
  "component---src-pages-projects-segmenting-and-labeling-pointclouds-in-vr-js": () => import("./../../../src/pages/projects/segmenting-and-labeling-pointclouds-in-vr.js" /* webpackChunkName: "component---src-pages-projects-segmenting-and-labeling-pointclouds-in-vr-js" */),
  "component---src-pages-projects-suedpark-urban-planning-js": () => import("./../../../src/pages/projects/suedpark-urban-planning.js" /* webpackChunkName: "component---src-pages-projects-suedpark-urban-planning-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

